/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

.user-mat-menu {
    background: red;
}

.alert-confirm {
    --max-width: 60%;
    .alert-message {
        font-size: 1.4rem;
    }
    .loading-content {
        font-size: 1.4rem;
    }
}

.comment-prompt {
    background-color: yellow !important;
    height: 150px;
}

.chea-mat-spinner {
    margin: 10% auto 10px auto;
    circle {
      stroke: -var(--ion-color-primary);
    }
}

.alert-modal {
    // color: black;
    background-color: white;
    height: 100%;
    padding: 20px; 
    position: relative;
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: right; 
    }
    .copy-button {
      position: absolute;
      top: 10px;
      left: 10px;
      text-align: left; 
    }
    textarea {
      padding: 30px 8px; 
      width: 100%; 
      height: 100%; 
      font-size: 15px;
      border: 0;
    }
    ion-icon {
      font-size: 24px;
    }
    .content {
        font-size: 15px;
        padding: 20px 16px;
    }
  }

  .plus-action-sheet {
    .action-sheet-title {
      text-align: center;
      font-size: 1.2em;
      color: blue;
    }
    .action-sheet-button {
      text-align: center !important;
      .action-sheet-button-inner {
        font-size: 1em;
        justify-content: center;
      }
    }
    .action-sheet-icon {
      padding-right: 1px;
    }
  }